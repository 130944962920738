import { Survicate } from "@survicate/survicate-web-surveys-wrapper";
import { getSurvicateInstance, initSurvicate } from "@survicate/survicate-web-surveys-wrapper/widget_wrapper";
import { PropsWithChildren, useEffect, useMemo, useState } from "react";

import { createContext } from "../createContext";
import { SURVICATE_PLACEHOLDER } from "./constants";

const [SurvicateContext, useSurvicate] = createContext<Survicate>({
    defaultValue: SURVICATE_PLACEHOLDER,
    providerName: "SurvicateProvider",
});

export { useSurvicate };

type Props = PropsWithChildren<{
    isAppReady?: boolean;
}>;

export const SurvicateProvider = ({ children, isAppReady = true }: Props) => {
    const workspaceKey = process.env.REACT_APP_SURVICATE_WORKSPACE_KEY;
    const [survicateInstance, setSurvicateInstance] = useState<Survicate | null>(null);
    const instance = useMemo(() => survicateInstance ?? SURVICATE_PLACEHOLDER, [survicateInstance]);

    useEffect(() => {
        const initialize = async () => {
            await initSurvicate({ workspaceKey });
            const instance = getSurvicateInstance();
            setSurvicateInstance(instance);
        };

        if (isAppReady && workspaceKey) {
            initialize();
        }
    }, [isAppReady, workspaceKey]);

    return <SurvicateContext.Provider value={instance}>{children}</SurvicateContext.Provider>;
};
